import { css } from '@emotion/core';
import NeufileGroteskRegularWoff2 from '../fonts/neufile-grotesk-regular.woff2';
import NeufileGroteskRegularWoff from '../fonts/neufile-grotesk-regular.woff';
import NeufileGroteskBoldWoff2 from '../fonts/neufile-grotesk-bold.woff2';
import NeufileGroteskBoldWoff from '../fonts/neufile-grotesk-bold.woff';
import TiemposHeadlineLightWoff2 from '../fonts/tiempos-headline-light.woff2';
import TiemposHeadlineLightWoff from '../fonts/tiempos-headline-light.woff';


const fontFaces = css`
	@font-face {
		font-family: 'Neufile Grotesk';
		src: url(${NeufileGroteskRegularWoff2}) format('woff2'), url(${NeufileGroteskRegularWoff}) format('woff');
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: 'Neufile Grotesk';
		src: url(${NeufileGroteskBoldWoff2}) format('woff2'), url(${NeufileGroteskBoldWoff}) format('woff');
		font-weight: bold;
		font-style: normal;
	}
	@font-face {
		font-family: 'Tiempos Headline';
		src: url(${TiemposHeadlineLightWoff2}) format('woff2'), url(${TiemposHeadlineLightWoff}) format('woff');
		font-weight: normal;
		font-style: normal;
	}
`;

export default fontFaces;
