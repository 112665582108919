import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { smUp, xxlUp } from '../styles/breakpoints';

const styles = css`
	padding: 0;
	margin: 0 0 0 -50px;
	list-style: none;
	font-weight: bold;
	font-size: 1.5em;
	> li {
		margin-left: 50px;
	}
	@media ${smUp} {
		display: flex;
		font-size: 1.125em;
	}
	@media ${xxlUp} {
		display: block;
		font-size: 1.875em;
		> li {
			margin-bottom: 50px;
		}
	}
`;

const MenuList = ({ children }) => (
	<ul css={styles}>
		{React.Children.map(children, child => (
			<li key={child}>
				{child}
			</li>
		))}
	</ul>
);

MenuList.propTypes = {
	children: PropTypes.node.isRequired,
};

export default MenuList;
