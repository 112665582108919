import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const styles = css`
	flex-grow: 1;
`;

const Main = ({ children }) => (
	<main role="main" css={styles}>
		{children}
	</main>
);

Main.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Main;
