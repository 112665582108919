import COLORS from './colors';

const defaultFontStack = [
	'-apple-system',
	'BlinkMacSystemFont',
	'Segoe UI',
	'Roboto',
	'Helvetica',
	'Arial',
	'sans-serif',
];

const baseFontFamily = 'Neufile Grotesk';
const secondaryFontFamily = 'Tiempos Headline';

const theme = {
	baseFontSize: '16px',
	baseLineHeight: 1.625,
	headingsLineHeight: 1.2,
	scaleRatio: 1.25,
	baseFontFamily: [
		baseFontFamily,
		...defaultFontStack,
	],
	headingsFontFamily: [
		baseFontFamily,
		...defaultFontStack,
	],
	secondaryFontFamily: [
		secondaryFontFamily,
		baseFontFamily,
		...defaultFontStack,
	],
	baseColor: COLORS.grayDarker,
	baseMarginBottom: 20 / (16 * 1.625),
	pageWidth: '62rem',
	pageGutter: '1.625rem',
};

export default theme;
