import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { xsDown } from '../styles/breakpoints';

const styles = css`
	margin: 0 20px 0 0;
	@media ${xsDown} {
		font-size: 15px;
	}
`;

const FooterCopy = ({ children }) => (
	<p css={styles}>
		{children}
	</p>
);

FooterCopy.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FooterCopy;
