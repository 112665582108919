import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { xxlUp } from '../../styles/breakpoints';

const styles = css`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
	@media ${xxlUp} {
		padding-left: 50em;
	}
`;

const Page = ({ children, id }) => (
	<div css={styles} id={id}>
		{children}
	</div>
);

Page.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Page;
