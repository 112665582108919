import { css } from '@emotion/core';
import { smUp } from './breakpoints';
import COLORS from './colors';
import theme from './theme';
import {
	convertPxToEm, normalizeFontFamily, parseUnit, verticalRhythm,
} from './utils';

const baseFontSize = parseUnit(theme.baseFontSize).value;
const pxToEm = convertPxToEm(baseFontSize);
const rhythm = verticalRhythm({
	base: baseFontSize,
	lineHeight: theme.baseLineHeight,
	unit: 'rem',
});

const typography = css`
	html {
		font-size: ${theme.baseFontSize};
		line-height: ${theme.baseLineHeight};
		font-family: ${theme.baseFontFamily.map(normalizeFontFamily).join(',')};
		color: ${theme.baseColor};
		overflow-y: scroll;
	}
	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		line-height: ${theme.headingsLineHeight};
		font-family: ${theme.headingsFontFamily.map(normalizeFontFamily).join(',')};
		font-weight: bold;
		margin-top: ${rhythm(theme.baseMarginBottom * 2.5)};
		margin-bottom: ${rhythm(theme.baseMarginBottom)};
		color: ${COLORS.black};
		&:first-child {
			margin-top: 0;
		}
	}
	h1,
	.h1 {
		font-size: ${pxToEm(34)};
		@media ${smUp} {
			font-size: ${pxToEm(44)};
		}
	}
	h2,
	.h2 {
		font-size: ${pxToEm(24)};
		line-height: ${44 / 30};
		@media ${smUp} {
			font-size: ${pxToEm(30)};
		}
	}
	h3,
	.h3 {
		font-size: ${pxToEm(24)};
		line-height: ${34 / 24};
	}
	h4,
	.h4 {
		font-size: ${pxToEm(20)};
		line-height: ${30 / 20};
	}
	h5,
	.h5 {
		font-size: ${pxToEm(18)};
		line-height: ${28 / 18};
	}
	h6,
	.h6 {
		font-size: ${pxToEm(15)};
		line-height: ${28 / 15};
		text-transform: uppercase;
	}
	ul,
	ol,
	dl,
	p,
	figure,
	table,
	fieldset,
	blockquote,
	form,
	iframe,
	hr,
	address {
		margin-top: 0;
		margin-bottom: ${rhythm(theme.baseMarginBottom)};
	}
	a {
		color: inherit;
		text-decoration: underline;
		transition: color .2s ease-out;
		&[href^="tel:"] {
			text-decoration: none;
		}
		&[href^="mailto"] {
			color: ${COLORS.primary};
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		&:hover {
			text-decoration: none;
			color: ${COLORS.primaryDark};
		}
	}
	blockquote {
		font-size: 20px;
		line-height: ${34 / 24};
		font-family: ${theme.secondaryFontFamily.map(normalizeFontFamily).join(',')};
		font-weight: 300;
		padding: 25px 71px 25px 25px;
		background-color: ${COLORS.secondaryLighter};
		margin: 50px 0;
		position: relative;
		min-height: 148px;
		&::after {
			content: '';
			width: 21px;
			height: 16px;
			background-position: 50% 50%;
			background-size: cover;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='63' height='48' viewBox='0 0 63 48'%3E%3Cpath fill='%23abdce7' d='M63 0v23.172C63 40.11 51.453 48 36 48v-8.276c11.038-.108 14.943-6.519 15.187-16.552H36V0h27zM27 0v23.172C27 40.11 15.453 48 0 48v-8.276c11.038-.108 14.943-6.519 15.188-16.552H0V0h27z'/%3E%3C/svg%3E");
			position: absolute;
			top: 30px;
			right: 25px;
		}
		> * {
			max-width: 480px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		@media ${smUp} {
			font-size: 24px;
			padding: 50px 163px 50px 50px;
			&::after {
				width: 63px;
				height: 48px;
				top: 50px;
				right: 50px;
			}
		}
	}
	figure {
		margin: 0;
	}
`;

export default typography;
