import { css } from '@emotion/core';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {
	xlDown, xlUp, xxlDown, xxlUp,
} from '../styles/breakpoints';

const styles = css`
	display: block;
	margin-right: 50px;
	&:hover {
		color: inherit;
	}
	svg {
		transition: width .2s ease-out, height .2s ease-out;
		will-change: width, height;
	}
	@media ${xlUp} and ${xxlDown} and (max-height: 47.98em) {
		svg {
			width: 197px;
			height: auto;
		}
	}
	@media ${xlDown} {
		svg {
			width: 148px;
			height: auto;
		}
	}
	@media ${xlUp} and ${xxlDown} and (min-height: 48em) {
		.headroom:not(.headroom--unfixed) & svg {
			width: 197px;
			height: auto;
		}
	}
	@media ${xxlUp} {
		margin: 0 0 100px 0;
	}
`;

const HeaderLogo = ({ children }) => (
	<Link to="/" css={styles}>
		{children}
	</Link>
);

HeaderLogo.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderLogo;
