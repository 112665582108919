import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { smUp } from '../../styles/breakpoints';
import theme from '../../styles/theme';


const styles = css`
	max-width: ${theme.pageWidth};
	padding: 0 20px;
	margin: 0 auto;
	@media ${smUp} {

		padding: 0 ${theme.pageGutter};
	}
`;

const Container = ({ children, id }) => (
	<div css={styles} id={id}>
		{children}
	</div>
);

Container.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Container;
