import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { smUp } from '../../styles/breakpoints';
import COLORS from '../../styles/colors';

const styles = css`
	background-color: ${COLORS.secondaryLight};
	padding: 50px 0;
	@media ${smUp} {
		padding: 35px 0;
	}
`;

const Footer = ({ children }) => (
	<footer role="contentinfo" css={styles}>
		{children}
	</footer>
);

Footer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Footer;
