import { css } from '@emotion/core';
import React from 'react';
import { Link } from 'react-scroll';
import COLORS from '../styles/colors';
import Icon from './core/Icon';

const styles = ({ toTop }) => css`
	display: inline-block;
	color: ${COLORS.white};
	cursor: pointer;
	&:hover {
		color: ${COLORS.primary};
	}
	${toTop && css`
		transform: scaleY(-1);
	`}
`;

const ScrollTo = ({ to, toTop }) => (
	<Link
		to={to}
		css={styles({ toTop })}
		smooth
		duration={500}
		offset={-150}
	>
		<Icon icon="angleDownCircle" />
	</Link>
);

export default ScrollTo;
