import { css } from '@emotion/core';
import React from 'react';
import { smUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';

const styles = css`
	padding: 50px 0;
	background-color: ${COLORS.secondaryLighter};
	@media ${smUp} {
		padding: 100px 0;
	}
`;

const CtaBox = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

export default CtaBox;
