import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { xxlUp } from '../styles/breakpoints';

const styles = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media ${xxlUp} {
		display: block;
	}
`;

const HeaderWrap = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

HeaderWrap.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderWrap;
