import { css } from '@emotion/core';
import { xxlUp } from './breakpoints';
import COLORS from './colors';

const headroom = css`
	.headroom-wrapper {
		background-color: ${COLORS.secondary};
	}
	.headroom {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
	}
	@media ${xxlUp} {
		.headroom-wrapper {
			height: auto !important;
		}
		.headroom {
			position: static;
		}
	}
`;

export default headroom;
