import { css } from '@emotion/core';
import React from 'react';
import { smUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';

const styles = css`
	margin-bottom: 0;
	font-size: 24px;
	line-height: ${40 / 30};
	font-weight: bold;
	> a {
		text-decoration: none;
		color: ${COLORS.primary};
		&:hover {
			text-decoration: underline;
			color: ${COLORS.primaryDark};
		}
	}
	@media ${smUp} {
		font-size: 30px;
	}
`;

const MailLink = ({ to, children }) => (
	<p css={styles}>
		<a href={`mailto:${to}`}>{children}</a>
	</p>
);

export default MailLink;
