import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { smUp } from '../styles/breakpoints';

const styles = css`
	position: absolute;
	top: 35px;
	right: 72px;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-out, visibility .2s ease-out;
	.headroom:not(.headroom--unfixed) & {
		opacity: 1;
		visibility: visible;
	}
	@media ${smUp} {
		display: none;
	}
`;

const HeaderToTop = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

HeaderToTop.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderToTop;
