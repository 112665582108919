import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { xlUp, xxlUp, xxlDown } from '../../styles/breakpoints';
import COLORS from '../../styles/colors';

const styles = css`
	background-color: ${COLORS.secondary};
	color: ${COLORS.black};
	padding: 37px 20px 33px;
	position: relative;
	@media ${xlUp} {
		padding: 75px 100px;
	}
	@media ${xlUp} and ${xxlDown} and (max-height: 47.98em) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@media ${xlUp} and ${xxlDown} and (min-height: 48em) {
		transition: padding .2s ease-out;
		will-change: padding;
		.headroom:not(.headroom--unfixed) & {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	@media ${xxlUp} {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		width: 50em;
		z-index: 1000;
		overflow-y: auto;
	}
`;

const Header = ({ children }) => (
	<header role="banner" css={styles}>
		{children}
	</header>
);

Header.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Header;
