import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { smDown } from '../styles/breakpoints';

const styles = css`
	@media ${smDown} {
		margin-top: 20px;
	}
`;

const FooterSocials = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

FooterSocials.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FooterSocials;
