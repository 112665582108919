import { Global } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import Headroom from 'react-headroom';
import Icon from '../components/core/Icon';
import CtaBox from '../components/CtaBox';
import FooterCopy from '../components/FooterCopy';
import FooterSocials from '../components/FooterSocials';
import FooterTextSeparator from '../components/FooterTextSeparator';
import FooterWrap from '../components/FooterWrap';
import HeaderLogo from '../components/HeaderLogo';
import HeaderMenu from '../components/HeaderMenu';
import HeaderWrap from '../components/HeaderWrap';
import HeaderToTop from '../components/HeaderToTop';
import Container from '../components/layout/Container';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import Main from '../components/layout/Main';
import Page from '../components/layout/Page';
import MailLink from '../components/MailLink';
import MainTitle from '../components/MainTitle';
import MenuLink from '../components/MenuLink';
import MenuList from '../components/MenuList';
import SocialsLink from '../components/SocialsLink';
import SocialsList from '../components/SocialsList';
import ScrollTo from '../components/ScrollTo';
import globals from '../styles/globals';
import typography from '../styles/typography';
import headroom from '../styles/headroom';
import getCurrentYear from '../utils/getCurrentYear';

const MainLayout = ({ children }) => (
	<Page id="top">
		<Global styles={[globals, typography, headroom]} />

		<Headroom disableInlineStyles>
			<Header>
				<HeaderWrap>
					<HeaderLogo>
						<Icon icon="logo" width={246} height={50} />
					</HeaderLogo>

					<HeaderToTop>
						<ScrollTo to="top" toTop />
					</HeaderToTop>

					<HeaderMenu>
						<MenuList>
							<MenuLink to="/me-advokatni-sluzby/">
								Mé advokátní služby
							</MenuLink>
							<MenuLink to="/kdo-jsem/">
								Kdo jsem
							</MenuLink>
							{/* <MenuLink to="/blog/">
								Blog
							</MenuLink> */}
							<MenuLink to="/kontakty/">
								Kontakty
							</MenuLink>
						</MenuList>
					</HeaderMenu>
				</HeaderWrap>
			</Header>
		</Headroom>

		<Main>
			{children}
		</Main>


		<CtaBox>
			<Container>
				<MainTitle>
					Podnikejte, vymýšlejte a&nbsp;tvořte!<br />
					Starost o&nbsp;vaše právní záležitosti už nechte na&nbsp;mně.
				</MainTitle>
				<MailLink to="ondrej@palatlegal.cz">office@palatlegal.cz</MailLink>
			</Container>
		</CtaBox>


		<Footer>
			<Container>
				<FooterWrap>
					<FooterCopy>
						&copy;&nbsp;{getCurrentYear()} Mgr.&nbsp;Ondřej&nbsp;Palát <FooterTextSeparator /> Sídlo:&nbsp;Konviktská&nbsp;291/24, Praha–Staré&nbsp;Město 110&nbsp;00 <FooterTextSeparator /> IČO:&nbsp;05417929
					</FooterCopy>

					<FooterSocials>
						<SocialsList>
							{/* <SocialsLink href="https://www.facebook.com/">
								<Icon icon="facebook" />
							</SocialsLink>
							<SocialsLink href="https://www.twitter.com/">
								<Icon icon="twitter" />
							</SocialsLink> */}
							<SocialsLink href="https://www.linkedin.com/in/ondřej-palát-645bb35b/">
								<Icon icon="linkedin" />
							</SocialsLink>
						</SocialsList>
					</FooterSocials>
				</FooterWrap>
			</Container>
		</Footer>
	</Page>
);

MainLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default MainLayout;
