import React from 'react';
import { css } from '@emotion/core';

const styles = css`
	margin: 0 10px;
`;

const FooterTextSeparator = () => (
	<span css={styles}>•</span>
);

export default FooterTextSeparator;
