import { css } from '@emotion/core';
import fontFaces from './fontFaces';

const globals = css`
	html {
		box-sizing: border-box;
	}
	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}
	${fontFaces}
`;

export default globals;
