import { css } from '@emotion/core';
import React from 'react';
import { smUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';
import theme from '../styles/theme';
import { normalizeFontFamily } from '../styles/utils';

const styles = css`
	font-size: 34px;
	line-height: ${60 / 44};
	font-weight: 300;
	font-family: ${theme.secondaryFontFamily.map(normalizeFontFamily).join(',')};
	color: ${COLORS.black};
	@media ${smUp} {
		font-size: 44px;
	}
`;

const MainTitle = ({ children, tag }) => {
	const Tag = tag || 'p';

	return (
		<Tag css={styles}>
			{children}
		</Tag>
	);
};

export default MainTitle;
