import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import COLORS from '../styles/colors';

const styles = css`
	display: block;
	&:hover {
		color: ${COLORS.primary};
	}
`;

const SocialsLink = ({ href, children }) => (
	<a href={href} target="_blank" rel="noopener noreferrer" css={styles}>
		{children}
	</a>
);

SocialsLink.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default SocialsLink;
