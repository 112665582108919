const GENERIC_FONT_FAMILIES = [
	'serif',
	'sans-serif',
	'monospace',
	'-apple-system',
];

export const normalizeFontFamily = fontFamily => (
	GENERIC_FONT_FAMILIES.indexOf(fontFamily) !== -1
		? fontFamily
		: `'${fontFamily}'`
);

export const modularScale = ({
	base,
	ratio,
	unit = '',
}) => (step = 0) => `${Number(((base * (ratio ** step)) / base).toFixed(5))}${unit}`;

export const verticalRhythm = ({
	base,
	lineHeight,
	unit = '',
}) => (length = 1) => `${((base * lineHeight * length) / base)}${unit}`;

export const parseUnit = (string) => {
	const NUMBER_REGEX = /-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/;

	const value = parseFloat(string);
	const numberStrings = string.match(NUMBER_REGEX) || [];
	const unit = numberStrings.length > 0
		? string.substr(numberStrings[0].length).trim()
		: '';

	return {
		value,
		unit,
	};
};

export const convertPxToEm = baseFontSize => fontSize => `${fontSize / baseFontSize}em`;
