import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const styles = css`
	padding: 0;
	margin: 0 0 0 -20px;
	list-style: none;
	display: flex;
	> li {
		margin-left: 20px;
	}
`;

const SocialsList = ({ children }) => (
	<ul css={styles}>
		{React.Children.map(children, child => (
			<li key={child}>
				{child}
			</li>
		))}
	</ul>
);

SocialsList.propTypes = {
	children: PropTypes.node.isRequired,
};

export default SocialsList;
