import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { smDown, smUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';
import Icon from './core/Icon';

const styles = ({ isMenuOpen }) => css`
	.menu-toggle {
		appearance: none;
		border: 0;
		padding: 0;
		outline: none;
		background: none;
		position: absolute;
		top: 34px;
		right: 20px;
	}
	@media ${smDown} {
		.menu-wrap {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			padding: 35px 50px;
			background-color: ${COLORS.secondary};
			opacity: ${isMenuOpen ? 1 : 0};
			visibility: ${isMenuOpen ? 'visible' : 'hidden'};
			transition: opacity .2s ease-out, visibility .2s ease-out;
			z-index: 1;
		}
	}
	@media ${smUp} {
		.menu-toggle {
			display: none;
		}
	}
`;

const HeaderMenu = ({ children }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<div css={styles({ isMenuOpen })}>
			<button type="button" className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
				<Icon icon={isMenuOpen ? 'close' : 'menu'} width={32} height={32} />
			</button>

			<div className="menu-wrap">
				{children}
			</div>
		</div>
	);
};

HeaderMenu.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderMenu;
