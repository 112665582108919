export const xsDown = '(max-width: 29.98em)';
export const xsUp = '(min-width: 30em)';
export const smDown = '(max-width: 47.98em)';
export const smUp = '(min-width: 48em)';
export const mdDown = '(max-width: 63.98em)';
export const mdUp = '(min-width: 64em)';
export const xlDown = '(max-width: 79.98em)';
export const xlUp = '(min-width: 80em)';
export const xxlDown = '(max-width: 149.98em)';
export const xxlUp = '(min-width: 150em)';
