import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { smUp } from '../styles/breakpoints';

const styles = css`
	@media ${smUp} {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

const FooterWrap = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

FooterWrap.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FooterWrap;
