import { css } from '@emotion/core';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { smDown, smUp, xxlUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';

const styles = css`
	display: block;
	text-decoration: none;
	position: relative;
	white-space: nowrap;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 2px;
		background-color: ${COLORS.primary};
		transform: scaleX(0);
		transition: opacity .2s ease-out, transform .2s ease-out;
	}
	&:hover,
	&.is-active {
		color: ${COLORS.primary};
	}
	@media ${smDown} {
		padding: 15px 40px;
		margin: 0 -50px;
	}
	@media ${smUp} {
		&:hover {
			&::after {
				opacity: 1;
				transform: scaleX(1);
			}
		}
	}
	@media ${xxlUp} {
		display: inline-block;
	}
`;

const SocialsLink = ({ to, children }) => (
	<Link to={to} css={styles} activeClassName="is-active">
		{children}
	</Link>
);

SocialsLink.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default SocialsLink;
