const COLORS = {
	primary: '#E5004F',
	primaryDark: '#BF0043',
	primaryLight: '#FBD9E5',
	secondary: '#ABDCE7',
	secondaryLight: '#E5F4F7',
	secondaryLighter: '#EEF8FA',
	black: '#000000',
	grayDarker: '#2F3D40',
	grayDark: '#656A6A',
	gray: '#999897',
	grayLight: '#D4D3D2',
	grayLighter: '#F2F1F0',
	white: '#FCFCFD',
};

export default COLORS;
